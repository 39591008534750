<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />

    <v-form
      ref="form"
      v-model="formValidity"
      valid-v-on="formValidity"
      class="contact-form"
      @submit.prevent="sendEmail"
    >
      <base-text-field
        v-model="nameField"
        :rules="nameRule"
        label="Name"
        name="user_name"
      />

      <base-text-field
        v-model="emailField"
        :rules="emailRule"
        label="Email"
        name="user_email"
      />

      <base-text-field
        v-model="subjectField"
        :rules="subjectRule"
        label="Subject"
        name="user_subject"
      />

      <base-textarea
        class="mb-6"
        label="Your Need & Description"
        name="message"
      />

      <base-btn
        :color="!theme.isDark ? 'accent' : 'white'"
        :disabled="!formValidity"
        outlined
        target="_blank"
        type="submit"
      >
        Send message
      </base-btn>
    </v-form>
  </div>
</template>

<script>
  import emailjs from 'emailjs-com'
  export default {
    name: 'BaseContactForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'MAIL US YOUR MESSAGE',
      },

    },
    data: () => ({
      nameField: '',
      emailField: '',
      subjectField: '',
      formValidity: false,
      nameRule: [
        nameValue => !!nameValue || 'Name is required.',
        nameValue => nameValue.length > 2 || 'Minimum length is 2.',
      ],
      emailRule: [
        emailValue => !!emailValue || 'Email is required',
        emailValue => emailValue.indexOf('@') !== 0 || 'Email should have a username.',
        emailValue => emailValue.includes('@') || 'Email include an @',
        emailValue => emailValue.indexOf('.') - emailValue.indexOf('@') > 1 || 'Email should contain a valid domain',
        emailValue => emailValue.indexOf('.') <= emailValue.length - 3 || 'Email should contain a valid domain extension',

      ],
      subjectRule: [
        subjectValue => !!subjectValue || 'Subject is required',
      ],

    }),
    methods: {
      sendEmail: (e) => {
        emailjs.sendForm('service_7hs617n', 'template_odhan5j', e.target, 'user_wgLx9atjvg3TW6OAxccnw')
          .then((result) => {
            console.log('SUCCESS!', result.status, result.text)
          }, (error) => {
            console.log('FAILED...', error)
          })
      },
    },
  }
</script>
